/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:65bc8ef1-5fcb-4391-8d71-be79ef91eefb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_636vcBst5",
    "aws_user_pools_web_client_id": "7i8lunkst8mhkf1lrnaq22btqd",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dashboardTable-sampledev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "dashboardApi",
            "endpoint": "https://jpxikbfld9.execute-api.us-east-1.amazonaws.com/sampledev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
